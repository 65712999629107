<template>
  <vx-card title="Commission" class="h-full" id="commission-card">
    <!-- CARD ACTION -->
    <div slot="no-body" v-if="supportTrackerRadialBar">

      <!-- Limit Notif -->
      <router-link class="danger-commission" :to="{ name: 'Invesment' }" v-if="series >= 100">
        <feather-icon
          icon="SlashIcon"
          class="p-3 inline-flex rounded-full text-danger"
        ></feather-icon>
        <p class="text-danger">
          Limit Exeed
        </p>
      </router-link>
      <!-- / -->
      <div class="vx-row text-center">
        <!-- Open Tickets Heading -->
        <div
          class="vx-col w-full lg:w-1/5 md:w-1/5 sm:w-1/5 flex flex-col justify-between mb-4 "
        >
          <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
            <h1 class="font-bold text-5xl">
              {{ dashboard.myMaxAllowedCommission }}
            </h1>
            <small>Max Allowed Commission</small>
          </div>
        </div>

        <!-- Chart -->
        <div
          class="vx-col w-full lg:w-4/5 md:w-4/5 sm:w-4/5 justify-center mx-auto lg:mt-0 md:mt-6 sm:mt-0 mt-6"
        >
          <VueApexCharts
            type="radialBar"
            height="370"
            :options="supportTrackerRadialBar.chartOptions"
            :series="series"
          />
        </div>
      </div>

      <!-- Support Tracker Meta Data -->
      <div class="flex flex-row flex-wrap justify-between px-8 pb-4 mt-4">
        <p class="text-center">
          <span class="block">Total Benefits</span>
          <span class="text-2xl font-semibold">{{
            dashboard.myTotalBenefit
          }}</span>
        </p>

        <p class="text-center">
          <span class="block">Pending Benefit</span>
          <span class="text-2xl font-semibold">{{
            dashboard.myPendingBenefit
          }}</span>
        </p>

        <p class="text-center">
          <span class="block">Burned Benefit</span>
          <span
            class="text-2xl font-semibold"
            :class="dashboard.myBurnedBenefit > 0 && 'text-danger'"
            >{{ dashboard.myBurnedBenefit }}</span
          >
        </p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
  components: { VueApexCharts },
  data() {
    return {
      supportTrackerRadialBar: {
        // series: [83],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 158,
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%"
              },
              track: {
                background: "rgba(0,0,0,0)",
                strokeWidth: "100%"
              },
              dataLabels: {
                value: {
                  offsetY: 30,
                  color: "#99a2ac",
                  fontSize: "2rem"
                }
              }
            }
          },
          colors: ["#EA5455"],
          fill: {
            type: "gradient",
            gradient: {
              // enabled: true,
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#7367F0"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            dashArray: 8
          },
          chart: {
            sparkline: {}
          },
          labels: ["Received"]
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      dashboard: "auth/dashboard"
    }),

    //
    series() {
      let total = parseInt(this.dashboard.myMaxAllowedCommission);
      let mine = parseInt(this.dashboard.myTotalBenefit);

      let persent = (mine * 100) / total;
      return [Math.round(persent)];
    }
  }
};
</script>
