<template>
  <div class="vx-col w-full md:w-1/2 lg:w-1/3 mb-base binary">
    <vx-card title="Binary Pool">
      <div slot="no-body">
        <!-- CHART -->
        <vue-apex-charts
          type="pie"
          height="345"
          class="mt-10 mb-10"
          :options="binaryPool.chartOptions"
          :series="series"
        />

        <!-- CHART DATA -->
        <ul class="mb-1">
          <li
            v-for="customerData in analyticsData"
            :key="customerData.customerType"
            class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
          >
            <span class="flex items-center">
              <span
                class="inline-block h-3 w-3 rounded-full mr-2"
                :class="`bg-${customerData.color}`"
              ></span>
              <span class="font-semibold">{{ customerData.customerType }}</span>
            </span>
            <span>{{ customerData.counts }}</span>
          </li>
        </ul>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
export default {
  components: { VueApexCharts },
  data() {
    return {
      // Binary Pool
      binaryPool: {
        chartOptions: {
          labels: ["Your Share", "Total Share"],
          dataLabels: {
            enabled: false
          },
          legend: { show: false },
          chart: {
            type: "pie",
            offsetY: 0,
            dropShadow: {
              enabled: false,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: 5
          },
          colors: ["#7961F9", "#FF9F43", "#EA5455"],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: ["#9c8cfc", "#FFC085", "#f29292"]
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      dashboard: "auth/dashboard"
    }),
    analyticsData() {
      return [
        {
          customerType: "Your Share",
          counts: parseInt(this.dashboard.myBinaryPoolShare),
          color: "primary"
        },
        {
          customerType: "Total Share",
          counts: parseInt(this.dashboard.totalBinaryPoolShares),
          color: "warning"
        },
        {
          customerType: "Total Treasury",
          counts: parseInt(this.dashboard.binaryPoolAmount),
          color: "success"
        }
      ];
    },

    series() {
      let myShare_share = parseInt(this.dashboard.myBinaryPoolShare);
      let total_share = parseInt(this.dashboard.totalBinaryPoolShares);
      return [myShare_share, total_share > 0 ? total_share - myShare_share : 1];
    }
  }
};
</script>
